import * as React from 'react';
import { connect, Dispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import autobind from 'autobind-decorator';
import * as moment from 'moment';

import { CreateUserParams } from '@sm/types/admin';
import { AccountStatus } from '@sm/types/openid';

import { StoreState } from '../../../../store';
import { setOnValidation } from '../../../../store/common/actions';
import { getOnValidation } from '../../../../store/common/selector';

import { UserInfo, UserInfoParams } from './UserInfo';
import { UserValidation } from '../UserValidation';

interface Props extends UserInfoParams, MapProps, DispatchProps, Partial<RouteComponentProps<RouteParams>> {
    usedEmails?: string[];
    onConfirmClick: (params: CreateUserParams) => void;
    onCancelClick: () => void;
}

interface RouteParams {
    organizationId?: string;
}

interface State {
    firstName: string;
    middleName: string;
    secondName: string;
    email: string;
    phoneNumber: string;
    status: AccountStatus;
    activeUntil: moment.Moment;
    isFirstNameValid: boolean;
    isSecondNameValid: boolean;
    isEmailValid: boolean;
    isPhoneNumberValid: boolean;
}

interface MapProps {
    onValidation?: boolean;
}

interface DispatchProps {
    setOnValidation?: (onValidation: boolean) => void;
}

@(withRouter as any)
@(connect(mapStateToProps, mapDispatchToProps) as any)
export class UserInfoContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            firstName: this.props.firstName,
            middleName: this.props.middleName,
            secondName: this.props.secondName,
            email: this.props.email,
            phoneNumber: this.props.phoneNumber,
            status: this.props.status,
            activeUntil: this.props.activeUntil,
            isFirstNameValid: !!this.props.firstName,
            isSecondNameValid: !!this.props.secondName,
            isEmailValid: !!this.props.email,
            isPhoneNumberValid: !!this.props.phoneNumber,
        };
    }

    public render(): JSX.Element {
        return React.createElement(UserInfo, {
            isNew: this.props.isNew,
            title: this.props.title,
            firstName: this.state.firstName,
            middleName: this.state.middleName,
            secondName: this.state.secondName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber.replace(/\D/g, ''),
            status: this.state.status,
            activeUntil: this.state.activeUntil,
            isFirstNameValid: this.state.isFirstNameValid,
            isSecondNameValid: this.state.isSecondNameValid,
            isEmailValid: this.state.isEmailValid,
            isPhoneNumberValid: this.state.isPhoneNumberValid,
            isOnValidation: this.props.onValidation,
            onFirstNameChange: this.handleFirstNameChange,
            onSecondNameChange: this.handleSecondNameChange,
            onMiddleNameChange: this.handleMiddleNameChange,
            onEmailChange: this.handleEmailChange,
            onPhoneChange: this.handlePhoneChange,
            onConfirmClick: this.handleConfirmClick,
            onCancelClick: this.handleCancelClick,
            onDateChange: this.handleDateChange,
        });
    }

    @autobind
    protected handleFirstNameChange(value: string) {
        const firstName = this.matchUserNameString(value);

        this.setState({
            firstName,
            isFirstNameValid: UserValidation.validateName(firstName),
        });
    }

    @autobind
    protected handleSecondNameChange(value: string) {
        const secondName = this.matchUserNameString(value);

        this.setState({
            secondName,
            isSecondNameValid: UserValidation.validateName(secondName),
        });
    }

    @autobind
    protected handleMiddleNameChange(value: string) {
        const middleName = this.matchUserNameString(value);

        this.setState({
            middleName,
        });
    }

    @autobind
    protected handleEmailChange(email: string) {
        this.setState({
            email,
            isEmailValid: UserValidation.validateEmail(email, this.props.usedEmails),
        });
    }

    @autobind
    protected handlePhoneChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            phoneNumber: event.target.value,
            isPhoneNumberValid: UserValidation.validatePhone(event.target.value),
        });
    }

    @autobind
    protected handleDateChange(activeUntil: moment.Moment) {
        this.setState({
            activeUntil,
        });
    }

    @autobind
    protected handleConfirmClick() {
        const { organizationId } = this.props.match.params;

        const userParams = {
            organizationId,
            firstName: this.state.firstName.trim(),
            middleName: this.state.middleName.trim(),
            secondName: this.state.secondName.trim(),
            email: this.state.email.trim(),
            phoneNumber: this.state.phoneNumber,
            activeUntil: this.state.activeUntil.format(),
            status: this.state.status,
        };

        const isValid = UserValidation.validateUser(userParams, this.props.usedEmails);

        if (isValid) {
            this.props.onConfirmClick({ ...userParams });
        }

        this.props.setOnValidation(!isValid);
    }

    @autobind
    protected handleCancelClick() {
        this.props.setOnValidation(false);
        this.props.onCancelClick();
    }

    private matchUserNameString(value: string): string {
        return value.match(/[а-яА-ЯёЁ\w \-()\"\'\.]/g)?.join('') || '';
    }
}

function mapStateToProps(state: StoreState): MapProps {
    return {
        onValidation: getOnValidation(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Props>): DispatchProps {
    return {
        setOnValidation: (onValidation: boolean) => dispatch(setOnValidation(onValidation)),
    };
}
